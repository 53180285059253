import { LOGIN, SSO_LOGIN } from '@constants/internal-route.constant';
import { IAppRoutes } from '@interface/common.interface';
import { lazy } from 'react';

export const AuthRoutes: IAppRoutes[] = [
  {
    link: LOGIN,
    element: lazy(() => import('@modules/auth/Login')),
  },
  {
    link: SSO_LOGIN,
    element: lazy(() => import('@modules/auth/AppLogin')),
  },
  {
    link: '*',
    redirect: LOGIN,
  },
];
