import { IObject } from '@interface/common.interface';

const DECIMAL_NUMERIC_REGEX = /^(\d+)?(\.)?(\d{0,2})?$/;
const BD_PHONE_REGEX = /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const isValidUrl = (url: string) => {
  url = url.trim();
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return !!pattern.test(url);
};

function checkIsActive(pathname: string, url: string) {
  const current = pathname.split(/[?#]/)[0];
  if (!current || !url) {
    return false;
  }
  if (current === url) {
    return true;
  }
  if (current.indexOf(url) > -1) {
    return true;
  }
  return false;
}

const checkRequiredFiles = (
  data: any,
  fields: Array<string | Array<string> | (string & Array<string>)>
) => {
  const returnObj: IObject = { hasError: true, fields: [] };
  fields.forEach((f) => {
    if (f instanceof Array) {
      // 1st index should be file sender property and 2nd index will be receiver property
      if (
        !(data?.[f[0]] instanceof File) &&
        (!data?.[f[1]] || !Object.keys(data?.[f[1]])?.length)
      ) {
        returnObj.fields.push(f[0]);
      } else if (data?.[f[0]] instanceof File) {
        data[f[1]] = null;
      }
    } else {
      if (!(data?.[f] instanceof File) && (!data?.[f] || !Object.keys(data?.[f])?.length)) {
        returnObj.fields.push(f);
      }
    }
  });
  returnObj.hasError = !!returnObj.fields.length;
  return returnObj;
};

export {
  isValidUrl,
  checkIsActive,
  checkRequiredFiles,
  DECIMAL_NUMERIC_REGEX,
  BD_PHONE_REGEX,
  EMAIL_REGEX,
};
