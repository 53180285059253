import { RESTORA, DISH_CATEGORY, DISH_ORDER, DISHES } from '@constants/internal-route.constant';
import { ROUTE_CODES } from '@constants/route-codes.constant';
import { IAppRoutes } from '@interface/common.interface';
import { lazy } from 'react';

export const RestoraRoutes: IAppRoutes = {
  link: RESTORA,
  routeCode: ROUTE_CODES.RESTORA,
  childrens: [
    {
      link: DISH_CATEGORY,
      routeCode: ROUTE_CODES.DISH_CATEGORY,
      element: lazy(() => import('@modules/restora/DishCategory')),
    },
    {
      link: DISHES,
      routeCode: ROUTE_CODES.DISHES,
      element: lazy(() => import('@modules/restora/Dishes')),
    },
    {
      link: DISH_ORDER,
      routeCode: ROUTE_CODES.DISH_ORDER,
      element: lazy(() => import('@modules/restora/DishOrder')),
    },
  ],
};
