import {POS} from '@constants/internal-route.constant'
import { ROUTE_CODES } from '@constants/route-codes.constant';
import {IAppRoutes} from '@interface/common.interface'
import {lazy} from 'react'

export const POSRoutes: IAppRoutes = {
  link: POS,
  routeCode: ROUTE_CODES.POS,
  element: lazy(() => import('@modules/pos')),
};
