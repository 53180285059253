import { ENV } from 'config/ENV.config';

export const setWindowTitle = (title: string = null) => {
  const t = title || window.location.pathname?.split('/')?.slice(1)?.join(' - ');
  document.title = t.charAt(0).toUpperCase() + t.slice(1);
};

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;

export const getUrlFromJson = (jsonStr) => {
  if (jsonStr) {
    return ENV.apiEndpoint + '/' + JSON.parse(jsonStr).url;
  }
  return '';
};

export const getFileBaseUrl = () => {
  return ENV.apiEndpoint + '/';
};

export const getUrlFromJsonArray = (jsonArrStr) => {
  if (jsonArrStr) {
    return ENV.apiEndpoint + '/' + JSON.parse(jsonArrStr)[0].url;
  }
  return '';
};
