import {
  PROCUREMENT,
  PURCHASE_ORDER,
  PURCHASE_ORDER_APPROVAL,
  PURCHASE_REQUISITION,
  PURCHASE_REQUISITION_APPROVAL,
  SUPPLIER_BILL_AUDIT,
  SUPPLIER_BILL_GEN,
} from '@constants/internal-route.constant'
import { ROUTE_CODES } from '@constants/route-codes.constant';
import {IAppRoutes} from '@interface/common.interface'
import {lazy} from 'react'

export const ProcurementRoutes: IAppRoutes = {
  link: PROCUREMENT,
  routeCode: ROUTE_CODES.PROCUREMENT,
  childrens: [
    {
      link: PURCHASE_REQUISITION,
      element: lazy(() => import('@modules/procurement/pur-requisition')),
    },
    {
      link: PURCHASE_REQUISITION_APPROVAL,
      element: lazy(() => import('@modules/procurement/pur-requisition-approvel')),
    },
    {
      link: PURCHASE_ORDER,
      element: lazy(() => import('@modules/procurement/purchase-order')),
    },
    {
      link: SUPPLIER_BILL_GEN,
      element: lazy(() => import('@modules/procurement/purchase-order')),
    },
    {
      link: PURCHASE_ORDER_APPROVAL,
      element: lazy(() => import('@modules/procurement/purchase-order-approval')),
    },
    {
      link: SUPPLIER_BILL_AUDIT,
      element: lazy(() => import('@modules/procurement/purchase-order-approval')),
    },
  ],
};
