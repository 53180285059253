import {
  COUPON,
  COUPON_ADD,
  GENERAL_SETTINGS,
  WEBSITE_MENU,
  PAYMENT_SETUP,
  SETTINGS,
  WEBSITE_MENU_CREATE,
} from '@constants/internal-route.constant';
import { ROUTE_CODES } from '@constants/route-codes.constant';
import { IAppRoutes } from '@interface/common.interface';
import { lazy } from 'react';

export const SettingsRoutes: IAppRoutes = {
  link: SETTINGS,
  routeCode: ROUTE_CODES.SETTING,
  childrens: [
    {
      link: PAYMENT_SETUP,
      routeCode: ROUTE_CODES.PAYMENT_SETUP_CRUD,
      element: lazy(() => import('@modules/settings/Payment-setup')),
    },
    {
      link: GENERAL_SETTINGS,
      routeCode: ROUTE_CODES.GENERAL_SETTING_CRUD,
      element: lazy(() => import('@modules/settings/general-settings')),
    },
    {
      link: COUPON,
      routeCode: ROUTE_CODES.COUPON_SETUP_CRUD,
      element: lazy(() => import('@modules/settings/coupon')),
    },
    {
      link: COUPON_ADD,
      routeCode: ROUTE_CODES.COUPON_SETUP_CRUD,
      element: lazy(() => import('@modules/settings/coupon/components/Form')),
    },
    {
      link: WEBSITE_MENU,
      routeCode: ROUTE_CODES.WEBSITE_MENU_CRUD,
      element: lazy(() => import('@modules/settings/menu/menu')),
    },
    {
      link: WEBSITE_MENU_CREATE,
      routeCode: ROUTE_CODES.WEBSITE_MENU_CRUD,
      element: lazy(() => import('@modules/settings/menu/Create/index')),
    },
  ],
};
