import {
  POS_SALES_ORDER,
  SALES,
  SALES_DETAILS,
  SALES_REFUND,
  SALES_RETURN,
} from '@constants/internal-route.constant';
import { ROUTE_CODES } from '@constants/route-codes.constant';
import {IAppRoutes} from '@interface/common.interface'
import {lazy} from 'react'

const SalesReturn = lazy(() => import('@modules/sales/pos-sale/Return'))

export const SalesRoutes: IAppRoutes = {
  link: SALES,
  routeCode: ROUTE_CODES.SALES,
  childrens: [
    {
      link: POS_SALES_ORDER,
      element: lazy(() => import('@modules/sales/pos-sale')),
    },
    {
      link: SALES_DETAILS({ id: ':id' }),
      element: lazy(() => import('@modules/sales/pos-sale/Details')),
    },
    {
      link: SALES_RETURN({ id: ':id' }),
      element: SalesReturn,
    },
    {
      link: SALES_REFUND({ id: ':id' }),
      element: SalesReturn,
    },
  ],
};
