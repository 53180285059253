/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from '_metronic/helpers';
import { toAbsoluteUrl } from '@services/helper/common.helper';
import { FormattedMessage } from 'react-intl';

const QuickLinks: FC = () => (
  <div
    className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
    data-kt-menu='true'
  >
    <div
      className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
      style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')` }}
    >
      <h3 className='fw-bold mb-3'> {<FormattedMessage id='QUICK_MENU.QUICK_LINKS' />}</h3>

      <span className='badge bg-primary py-2 px-3'>
        {' '}
        {<FormattedMessage id='QUICK_MENU.THREE_MODULES' />}
      </span>
    </div>

    <div className='row g-0'>
      <div className='col-6'>
        <a
          href='https://pos.tongdesk.com'
          target='_blank'
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
        >
          <KTSVG
            path='/media/icons/duotune/finance/fin007.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>
            {' '}
            {<FormattedMessage id='QUICK_MENU.INVENTORY_POS' />}
          </span>
          <span className='fs-7 text-gray-400'>{<FormattedMessage id='QUICK_MENU.MODULE' />}</span>
        </a>
      </div>

      <div className='col-6'>
        <a
          href='https://ams.tongdesk.com'
          target='_blank'
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom'
        >
          <KTSVG
            path='/media/icons/duotune/finance/fin001.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>
            {' '}
            {<FormattedMessage id='MENU.ACCOUNTING' />}
          </span>
          <span className='fs-7 text-gray-400'>{<FormattedMessage id='QUICK_MENU.MODULE' />}</span>
        </a>
      </div>

      <div className='col-6'>
        <a
          href='https://hrm.tongdesk.com'
          target='_blank'
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com014.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>
            {<FormattedMessage id='MENU.HRM' />}
          </span>
          <span className='fs-7 text-gray-400'>{<FormattedMessage id='QUICK_MENU.MODULE' />}</span>
        </a>
      </div>

      <div className='col-6'>
        <a href='#' className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'>
          <KTSVG
            path='/media/icons/duotune/finance/fin006.svg'
            className='svg-icon-3x svg-icon-primary mb-2'
          />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>
            {' '}
            {<FormattedMessage id='MENU.MARCHANDISING' />}
          </span>
          <span className='fs-7 text-gray-400'> {<FormattedMessage id='MENU.UPCOMING' />}</span>
        </a>
      </div>
    </div>

    <div className='py-2 text-center border-top'>
      <Link to='#' className='btn btn-color-gray-600 btn-active-color-primary'>
        {<FormattedMessage id='BUTTON.VIEW_ALL' />}{' '}
        <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
      </Link>
    </div>
  </div>
);

export { QuickLinks };
