/* eslint-disable jsx-a11y/anchor-is-valid */
import Icon from '@components/Icon';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const Dropdown2: FC = () => {
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-450px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
          {<FormattedMessage id='QUICK_MENU.QUICK_ACTIONS' />}
        </div>
      </div>

      <div className='separator mb-3 opacity-75'></div>
      <div className='row mb-3'>
        <div className='col-md-6'>
          <div className='menu-item px-3'>
            <Link to='/settings/general-settings' className='menu-link px-3'>
              <Icon icon='settings' size={25} variants='outlined' />
              &nbsp;{<FormattedMessage id='QUICK_MENU.GENERAL_SETTINGS' />}
            </Link>
          </div>
          <div className='menu-item px-3'>
            <Link to='/settings/payment-setup' className='menu-link px-3'>
              <Icon icon='payments' size={25} variants='outlined' />
              &nbsp;{<FormattedMessage id='QUICK_MENU.PAYMENT_SETUP' />}
            </Link>
          </div>
          <div className='menu-item px-3'>
            <Link to='/settings/menu' className='menu-link px-3'>
              <Icon icon='menu_open' size={25} variants='outlined' />
              &nbsp;{<FormattedMessage id='QUICK_MENU.WEBSITE_MENU' />}
            </Link>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='menu-item px-3'>
            <Link to='/settings/coupon' className='menu-link px-3'>
              <Icon icon='sell' size={25} variants='outlined' />
              &nbsp;{<FormattedMessage id='QUICK_MENU.COUPON_DISCOUNT' />}
            </Link>
          </div>

          <div className='menu-item px-3'>
            <Link to='/settings/delivery-setup' className='menu-link px-3'>
              <Icon icon='local_shipping' size={25} variants='outlined' />
              &nbsp;{<FormattedMessage id='QUICK_MENU.DELIVERY_SETUP' />}
            </Link>
          </div>
          <div className='menu-item px-3'>
            <Link to='/settings/short-url' className='menu-link px-3'>
              <Icon icon='link' size={25} variants='outlined' />
              &nbsp;{<FormattedMessage id='QUICK_MENU.SHORT_URL' />}
            </Link>
          </div>
          <div className='menu-item px-3'>
            <Link to='/settings/polices' className='menu-link px-3'>
              <Icon icon='policy' size={25} variants='outlined' />
              &nbsp;{<FormattedMessage id='QUICK_MENU.POLICIES' />}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Dropdown2 };
