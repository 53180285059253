import {
  SETUP,
  PRODUCT_ADD,
  PRODUCT_EDIT,
  PRODUCT_LIST,
  WAREHOUSE_LIST,
  VENDOR_LIST,
  COLLECTION_LIST,
  CATEGORY_LIST,
  UOM_LIST,
  CUSTOMER,
  SUPPLIER,
} from '@constants/internal-route.constant';
import { ROUTE_CODES } from '@constants/route-codes.constant';
import { IAppRoutes } from '@interface/common.interface';
import { lazy } from 'react';

export const SetupRoutes: IAppRoutes = {
  link: SETUP,
  routeCode: ROUTE_CODES.POS_SETUP,
  childrens: [
    {
      link: UOM_LIST,
      routeCode: ROUTE_CODES.SETUP_UOM,
      element: lazy(() => import('@modules/setup/Uom')),
    },
    {
      link: PRODUCT_LIST,
      routeCode: ROUTE_CODES.SETUP_PRODUCT,
      element: lazy(() => import('@modules/setup/Products')),
    },
    {
      link: PRODUCT_ADD,
      routeCode: ROUTE_CODES.SETUP_PRODUCT,
      element: lazy(() => import('@modules/setup/Products/Form')),
    },
    {
      link: PRODUCT_EDIT({ id: ':id' }),
      routeCode: ROUTE_CODES.SETUP_PRODUCT,
      element: lazy(() => import('@modules/setup/Products/Form')),
    },
    {
      link: CATEGORY_LIST,
      routeCode: ROUTE_CODES.SETUP_CATEGORY,
      element: lazy(() => import('@modules/setup/Category')),
    },
    {
      link: COLLECTION_LIST,
      routeCode: ROUTE_CODES.SETUP_COLLECTION,
      element: lazy(() => import('@modules/setup/Collection')),
    },
    {
      link: VENDOR_LIST,
      routeCode: ROUTE_CODES.SETUP_VENDOR,
      element: lazy(() => import('@modules/setup/Vendor')),
    },
    {
      link: WAREHOUSE_LIST,
      routeCode: ROUTE_CODES.SETUP_WAREHOUSE,
      element: lazy(() => import('@modules/setup/Warehouse')),
    },
    {
      link: CUSTOMER,
      routeCode: ROUTE_CODES.SETUP_CUSTOMER,
      element: lazy(() => import('@modules/setup/Customer')),
    },
    {
      link: SUPPLIER,
      routeCode: ROUTE_CODES.SETUP_SUPPLIER,
      element: lazy(() => import('@modules/setup/Supplier')),
    },
  ],
};
