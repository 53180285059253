import { DASHBOARD } from '@constants/internal-route.constant';
import { IAppRoutes } from '@interface/common.interface';
import { lazy } from 'react';
import { InventoryRoutes } from './inventory.routes';
import { POSRoutes } from './pos.routes';
import { SetupRoutes } from './setup.routes';
import { SettingsRoutes } from './settings.routes';
import { ProcurementRoutes } from './procurement.routes';
import { SalesRoutes } from './sale.routes';
import { RestoraRoutes } from './restora.routes';
import { ROUTE_CODES } from '@constants/route-codes.constant';

export const AppRouteList: IAppRoutes[] = [
  {
    link: DASHBOARD,
    element: lazy(() => import('pages/dashboard/DashboardWrapper')),
    routeCode: ROUTE_CODES.DASHBOARD,
  },
  { ...SetupRoutes },
  { ...RestoraRoutes },
  { ...InventoryRoutes },
  { ...POSRoutes },
  { ...SettingsRoutes },
  { ...ProcurementRoutes },
  { ...SalesRoutes },
  {
    link: 'auth/*',
    redirect: DASHBOARD,
  },
];
