export const ROUTE_CODES = {
  DASHBOARD: 'DASHBOARD',
  POS_SETUP: 'SETUP',
  SETUP_UOM: 'UOM_CRUD',
  SETUP_PRODUCT: 'PRODUCT_CURD',
  SETUP_CATEGORY: 'CATEGORY_CRUD',
  SETUP_COLLECTION: 'COLLECTION_CRUD',
  SETUP_VENDOR: 'VENDOR_CRUD',
  SETUP_WAREHOUSE: 'WAREHOUSE_CRUD',
  SETUP_CUSTOMER: 'CUSTOMER_CRUD',
  SETUP_SUPPLIER: 'SUPPLIER_CRUD',
  INVENTORY: 'INVENTORY',
  POS: 'POS',
  SETTING: 'SETTING',
  WEBSITE_MENU_CRUD: 'WEBSITE_MENU_CRUD',
  GENERAL_SETTING_CRUD: 'GENERAL_SETTING_CRUD',
  PAYMENT_SETUP_CRUD: 'PAYMENT_SETUP_CRUD',
  COUPON_SETUP_CRUD: 'COUPON_SETUP_CRUD',
  PROCUREMENT: 'PROCUREMENT',
  SALES: 'SALES',
  RESTORA: 'RESTORA',
  DISH_CATEGORY: 'DISH_CATEGORY_CRUD',
  DISH_ORDER: 'DISH_ORDER_CRUD',
  DISHES: 'DISHES_CRUD',
};
