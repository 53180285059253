/* eslint-disable jsx-a11y/anchor-is-valid */
import Icon from '@components/Icon'
import {COMPANY_LIST} from '@constants/internal-route.constant'
import {IUserInfo} from '@interface/auth.interface'
import {LocalStorageService} from '@services/utils/localStorage.service'
import {FC} from 'react'
import { FormattedMessage } from 'react-intl'
import {Link} from 'react-router-dom'

const CompanyList: FC = () => {
  const userInfo: IUserInfo = LocalStorageService.get('userInfo')


  return (
    <div className='menu-column'>
      <div
        className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
        style={{backgroundImage: `url('/media/misc/pattern-1.jpg')`}}
      >
        <h3 className='fw-bold mb-3'> {<FormattedMessage id='LEVEL.COMPANY_LIST' />} </h3>
        <span className='badge bg-primary py-2 px-3'>
        {<FormattedMessage id='LEVEL.TOTAL_COMPANY' />} {userInfo?.companies?.length || 0}
        </span>
      </div>

      <div className='row g-0'>
        {userInfo?.companies?.map((com) => (
          <div className='col-6' key={com?.companyId}>
            <a
              href='#'
              className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
            >
              <Icon icon='apartment' size={35} />
              <span className='fs-5 fw-bold text-gray-800 mb-0 mt-3'>{com?.companyName}</span>
              <span className='fs-7 text-gray-400'>{com?.roleName}</span>
            </a>
          </div>
        ))}
      </div>

      <div className='py-2 text-center border-top'>
        <Link
          to={COMPANY_LIST}
          className='btn btn-color-gray-600 btn-active-color-primary d-flex align-items-center justify-content-center gap-3'
        >
          {<FormattedMessage id='BUTTON.VIEW_ALL' />} <Icon icon='double_arrow' color='primary' size={20} />
        </Link>
      </div>
    </div>
  )
}

export {CompanyList}
