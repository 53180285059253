import {createRoot} from 'react-dom/client'
// Apps
import {AuthProvider} from '@context/Auth'
import {MetronicI18nProvider} from '_metronic/i18n/Metronici18n'
import {ThemeModeProvider} from '_metronic/partials/layout/theme-mode/ThemeModeProvider'
import 'assets/sass/plugins.scss'
import 'assets/sass/style.react.scss'
import 'assets/sass/style.scss'
import {ToastContainer} from 'react-toastify'
import {AppRoutes} from './routing/AppRoutes'

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <MetronicI18nProvider>
      <ThemeModeProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </ThemeModeProvider>
      <ToastContainer
        position='top-right'
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
    </MetronicI18nProvider>
  )
}
