import {
  INTERNAL_REQUISITION,
  INVENTORY,
  PURCHASES,
  STOCK,
  STOCK_TRANSFER,
} from '@constants/internal-route.constant'
import { ROUTE_CODES } from '@constants/route-codes.constant';
import {IAppRoutes} from '@interface/common.interface'
import {lazy} from 'react'

export const InventoryRoutes: IAppRoutes = {
  link: INVENTORY,
  routeCode: ROUTE_CODES.INVENTORY,
  childrens: [
    {
      link: STOCK,
      element: lazy(() => import('@modules/inventory/Stock')),
    },
    {
      link: STOCK_TRANSFER,
      element: lazy(() => import('@modules/inventory/stock-transfer')),
    },
    {
      link: PURCHASES,
      element: lazy(() => import('@modules/inventory/Purchases')),
    },
    {
      link: INTERNAL_REQUISITION,
      element: lazy(() => import('@modules/inventory/internal-requisition')),
    },
  ],
};
