export const HOME = '/';

export const AUTH = '/auth';
export const LOGIN = AUTH + '/login';
export const SSO_LOGIN = AUTH + '/sso-login';

export const COMPANY_LIST = '/company-list';
export const DASHBOARD = '/dashboard';

export const SETUP = '/setup';
export const PRODUCT_LIST = SETUP + '/product/list';
export const PRODUCT_ADD = SETUP + '/product/add';
export const PRODUCT_EDIT: ({ id }) => string = ({ id }) => `${SETUP}/product/edit/${id}`;
export const UOM_LIST = SETUP + '/uom';
export const CATEGORY_LIST = SETUP + '/category';
export const COLLECTION_LIST = SETUP + '/collection';
export const VENDOR_LIST = SETUP + '/vendor';
export const WAREHOUSE_LIST = SETUP + '/warehouse';
export const CUSTOMER = SETUP + '/customer';
export const SUPPLIER = SETUP + '/supplier';

export const RESTORA = '/restora';
export const DISH_CATEGORY = RESTORA + '/dish-category';
export const DISHES = RESTORA + '/dishes';
export const DISH_ORDER = RESTORA + '/dish-order';

//Procurement
export const PROCUREMENT = '/procurement';
export const PURCHASE_REQUISITION = PROCUREMENT + '/purchase-requisition';
export const PURCHASE_REQUISITION_APPROVAL = PROCUREMENT + '/pur-requisition-approval';
export const PURCHASE_ORDER = PROCUREMENT + '/purchase-order';
export const PURCHASE_ORDER_APPROVAL = PROCUREMENT + '/pur-order-approval';
export const SUPPLIER_BILL_GEN = PROCUREMENT + '/supplier-bill-gen';
export const SUPPLIER_BILL_AUDIT = PROCUREMENT + '/spl-bill-audit';

//inventory
export const INVENTORY = '/inventory';
export const INTERNAL_REQUISITION = INVENTORY + '/internal-requisition';
export const STOCK_TRANSFER = INVENTORY + '/transfer-stock';
export const PURCHASES = INVENTORY + '/purchases';
export const STORE_ADJUSTMENT = INVENTORY + '/store-adjustment';
export const STOCK = INVENTORY + '/stock';

//sales
export const SALES = '/sales';
export const WEB_SALES_ORDER = SALES + '/web-sale-order';
export const POS_SALES_ORDER = SALES + '/pos-sale-order';
export const SALES_DETAILS = ({ id }) => `${WEB_SALES_ORDER}/${id}`;
export const SALES_RETURN = ({ id }) => `${WEB_SALES_ORDER}/${id}/return`;
export const SALES_REFUND = ({ id }) => `${WEB_SALES_ORDER}/${id}/refund`;

//settings
export const SETTINGS = '/settings';
export const GENERAL_SETTINGS = SETTINGS + '/general-settings';
export const PAYMENT_SETUP = SETTINGS + '/payment-setup';
export const BILLING_INVOICE = SETTINGS + '/billing-invoice';
export const COUPON = SETTINGS + '/coupon';
export const COUPON_ADD = SETTINGS + '/add';
export const DELIVERY_SETUP = SETTINGS + '/delivery-setup';
export const STAFF_USER = SETTINGS + '/staff-users';

export const WEBSITE_MENU: string = SETTINGS + '/menu';
export const WEBSITE_MENU_CREATE: string = WEBSITE_MENU + '/create';
export const WEBSITE_MENU_EDIT: Function = ({ id }) => `${WEBSITE_MENU}/${id}`;

//
export const POS = '/pos';

//Reports
export const REPORTS = '/reports';
export const RPT_DAILY_SUMMARY = '/rpt-daily-summary';
export const RPT_PURCHASE_REQUISITION = '/rpt-purchase-requisition';
export const RPT_PURCHASE_ORDER = '/rpt-purchase-order';
export const RPT_PURCHASES = '/rpt-purchases';
export const RPT_SALES = '/rpt-sales';
export const RPT_RETURN = '/rpt-return';
export const RPT_REFUND = '/rpt-refund';
export const RPT_EXPENSES = '/rpt-expenses';
export const RPT_STOCK = '/rpt-stock';
