/* eslint-disable react/jsx-no-target-blank */
import { DASHBOARD, POS } from '@constants/internal-route.constant';
import { ROUTE_CODES } from '@constants/route-codes.constant';
import { useAuth } from '@context/Auth';
import { FormattedMessage } from 'react-intl';
import { AsideMenuItem } from './AsideMenuItem';

const sideMenus = [
  {
    to: DASHBOARD,
    title: 'MENU.DASHBOARD',
    icon: 'bi-columns-gap',
    route_code: ROUTE_CODES.DASHBOARD,
  },
  {
    to: POS,
    title: 'MENU.POS',
    icon: 'bi-cart4',
    route_code: ROUTE_CODES.POS,
  },
];

export const AsideMenuMain = () => {
  const {
    userInfo: { features },
  } = useAuth();

  return (
    <>
      {sideMenus.map((sm) =>
        features?.findIndex((f) => f?.code === sm.route_code) >= 0 ? (
          <AsideMenuItem
            key={sm?.route_code}
            to={sm.to}
            title={<FormattedMessage id={sm.title} />}
            fontIcon={`${sm.icon} fs-2`}
            className='py-2'
          />
        ) : null
      )}
      {/* <AsideMenuItemWithSubMain
        to='/crafted/pages'
        title='Crafted'
        fontIcon='bi-file-text'
        bsTitle='Crafted'
      >
        <AsideMenuItemWithSub to='/crafted/accounts' title='Accounts' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/account/overview'
            title='Overview'
            hasBullet={true}
            bsTitle='Overview'
          />
          <AsideMenuItem
            to='/crafted/account/settings'
            title='Settings'
            hasBullet={true}
            bsTitle='Settings'
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/apps/chat' title='Chat' hasBullet={true}>
          <AsideMenuItem
            to='/apps/chat/private-chat'
            title='Private Chat'
            bsTitle='Private Chat'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/apps/chat/group-chat'
            title='Group Chart'
            bsTitle='Group Chart'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/apps/chat/drawer-chat'
            title='Drawer Chart'
            bsTitle='Drawer Chart'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSubMain> */}
    </>
  );
};
