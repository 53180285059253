import { DASHBOARD } from '@constants/internal-route.constant';
import { useAuth } from '@context/Auth';
import { App } from 'App';
import AuthLayout from 'layouts/AuthLayout';
import ErrorsPage from 'pages/errors/ErrorsPage';
import { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { AuthRoutes } from './routes/auth.routes';

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          {isAuthenticated ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to={DASHBOARD} />} />
            </>
          ) : (
            <>
              <Route element={<AuthLayout />}>
                {AuthRoutes.map((r) => (
                  <Route
                    key={r.link}
                    path={r.link}
                    element={r.redirect ? <Navigate to={r.redirect} /> : <r.element />}
                  />
                ))}
              </Route>
            </>
          )}
          <Route path='*' element={<ErrorsPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
