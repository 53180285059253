interface IENV {
  appCode: string;
  productCode: string;
  apiEndpoint: string;
  envType?: string;
  ssoGetway: string;
  hrmGetway: string;
  ssoURL?: string;
}

export const ENV: IENV = {
  appCode: process.env.REACT_APP_CODE || '',
  productCode: process.env.REACT_APP_PRODUCT_CODE || '',
  envType: process.env.REACT_APP_ENV_TYPE || '',
  apiEndpoint: process.env.REACT_APP_GETWAY || '',
  ssoURL: process.env.REACT_APP_SSO_URL || '',
  ssoGetway: process.env.REACT_APP_SSO_GETWAY || '',
  hrmGetway: process.env.REACT_APP_HRM_GETWAY || '',
};
