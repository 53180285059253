import Icon from '@components/Icon';
import {
  CATEGORY_LIST,
  COLLECTION_LIST,
  DISHES,
  DISH_CATEGORY,
  DISH_ORDER,
  INTERNAL_REQUISITION,
  INVENTORY,
  POS_SALES_ORDER,
  PROCUREMENT,
  PRODUCT_LIST,
  PURCHASES,
  PURCHASE_ORDER,
  PURCHASE_ORDER_APPROVAL,
  PURCHASE_REQUISITION,
  PURCHASE_REQUISITION_APPROVAL,
  REPORTS,
  RESTORA,
  RPT_EXPENSES,
  RPT_PURCHASES,
  RPT_PURCHASE_ORDER,
  RPT_PURCHASE_REQUISITION,
  RPT_REFUND,
  RPT_RETURN,
  RPT_SALES,
  RPT_STOCK,
  SALES,
  SETUP,
  STOCK,
  STOCK_TRANSFER,
  STORE_ADJUSTMENT,
  SUPPLIER_BILL_AUDIT,
  SUPPLIER_BILL_GEN,
  UOM_LIST,
  VENDOR_LIST,
  WAREHOUSE_LIST,
  WEB_SALES_ORDER,
} from '@constants/internal-route.constant';
import { FaBalanceScale } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { MenuInnerWithSub } from './MenuInnerWithSub';
import { MenuItem } from './MenuItem';
import { CUSTOMER } from '@constants/internal-route.constant';
import { SUPPLIER } from '@constants/internal-route.constant';
import { ROUTE_CODES } from '@constants/route-codes.constant';
import { IObject } from '@interface/common.interface';
import { Fragment } from 'react';
import { useAuth } from '@context/Auth';

const menus = [
  {
    to: SETUP,
    title: 'MENU.SETUP',
    icon: 'bi-gear',
    route_code: ROUTE_CODES.POS_SETUP,
    children: [
      {
        to: UOM_LIST,
        title: 'MENU.UNIT_OF_MEASUREMENT',
        icon: 'bi-box',
        route_code: ROUTE_CODES.SETUP_UOM,
      },
      {
        to: CATEGORY_LIST,
        title: 'MENU.CATEGORY',
        icon: 'bi-diagram-3',
        route_code: ROUTE_CODES.SETUP_CATEGORY,
      },
      {
        to: COLLECTION_LIST,
        title: 'MENU.COLLECTION',
        icon: 'bi-diagram-2',
        route_code: ROUTE_CODES.SETUP_COLLECTION,
      },
      {
        to: VENDOR_LIST,
        title: 'MENU.VENDOR',
        icon: 'bi-people-fill',
        route_code: ROUTE_CODES.SETUP_VENDOR,
      },
      {
        to: WAREHOUSE_LIST,
        title: 'MENU.WAREHOUSE',
        icon: 'bi-shop',
        route_code: ROUTE_CODES.SETUP_WAREHOUSE,
      },
      {
        to: PRODUCT_LIST,
        title: 'MENU.PRODUCTS',
        icon: 'bi-archive',
        route_code: ROUTE_CODES.SETUP_PRODUCT,
      },
      {
        to: CUSTOMER,
        title: 'MENU.CUSTOMERS',
        icon: 'bi-people',
        route_code: ROUTE_CODES.SETUP_CUSTOMER,
        has_separator: true,
      },
      {
        to: SUPPLIER,
        title: 'MENU.SUPPLIERS',
        icon: 'bi-people',
        route_code: ROUTE_CODES.SETUP_SUPPLIER,
      },
    ],
  },
  {
    to: RESTORA,
    title: 'MENU.RESTORA',
    icon: 'bi-shop',
    route_code: ROUTE_CODES.RESTORA,
    children: [
      {
        to: DISH_CATEGORY,
        title: 'MENU.DISH_CATEGORY',
        icon: 'bi-fan',
        route_code: ROUTE_CODES.DISH_CATEGORY,
      },
      {
        to: DISHES,
        title: 'MENU.DISHES',
        icon: 'bi-fan',
        route_code: ROUTE_CODES.DISHES,
      },
      {
        to: DISH_ORDER,
        title: 'MENU.DISH_ORDER',
        icon: 'bi-cart',
        route_code: ROUTE_CODES.DISH_ORDER,
      },
    ],
  },
  {
    to: PROCUREMENT,
    title: 'MENU.PROCUREMENT',
    icon: 'bi-bag-plus',
    route_code: ROUTE_CODES.PROCUREMENT,
    children: [
      {
        to: PURCHASE_REQUISITION,
        title: 'MENU.PURCHASE_REQUISITION',
        icon: 'bi-cart',
      },
      {
        to: PURCHASE_REQUISITION_APPROVAL,
        title: 'MENU.REQUISITION_APPROVAL',
        icon: 'bi-check2-square',
      },
      {
        to: PURCHASE_ORDER,
        title: 'MENU.PURCHASE_ORDER',
        icon: 'bi-cart-check',
      },
      {
        to: PURCHASE_ORDER_APPROVAL,
        title: 'MENU.ORDER_APPROVAL',
        icon: 'bi-check2-square',
      },
      {
        to: SUPPLIER_BILL_GEN,
        title: 'MENU.SUPPLIER_BILL_GENERATE',
        icon: 'bi-receipt',
      },
      {
        to: SUPPLIER_BILL_AUDIT,
        title: 'MENU.SUPPLIER_BILL_AUDIT',
        icon: 'bi-check2-square',
      },
    ],
  },
  {
    to: INVENTORY,
    title: 'MENU.INVENTORY',
    icon: 'bi-basket',
    route_code: ROUTE_CODES.INVENTORY,
    children: [
      {
        to: INTERNAL_REQUISITION,
        title: 'MENU.INTERNAL_REQUISITION',
        icon: 'bi-cart-plus',
      },
      {
        to: STOCK_TRANSFER,
        title: 'MENU.TRANSFER_CONSUMPTION',
        cust_icon: <Icon icon='move_up' />,
      },
      {
        to: PURCHASES,
        title: 'MENU.RECEIVE_DIRECT_PUR',
        icon: 'bi-cart-plus',
      },
      {
        to: STORE_ADJUSTMENT,
        title: 'MENU.STORE_ADJUSTMENT',
        icon: 'bi-cart-x',
      },
      {
        to: STOCK,
        title: 'MENU.STOCKS',
        cust_icon: <FaBalanceScale />,
      },
    ],
  },
  {
    to: SALES,
    title: 'MENU.SALES',
    icon: 'bi-bag-dash',
    route_code: ROUTE_CODES.SALES,
    children: [
      {
        to: WEB_SALES_ORDER,
        title: 'MENU.WEB_SALE_ORDER',
        icon: 'bi-cart',
      },
      {
        to: PURCHASE_ORDER,
        title: 'MENU.SALE_INVOICE',
        icon: 'bi-cart-check',
      },
      {
        to: POS_SALES_ORDER,
        title: 'MENU.POS_SALE_ORDER',
        icon: 'bi-receipt',
      },
    ],
  },
  // {
  //   to: REPORTS,
  //   title: 'MENU.REPORTS',
  //   icon: 'bi-graph-up-arrow',
  //   route_code: ROUTE_CODES.REPORTS,
  // },
];

const renderSubMenu = (subList: IObject, features: IObject[]) =>
  subList?.map((sub) =>
    !sub?.route_code || features?.findIndex((f) => f?.code === sub.route_code) >= 0 ? (
      <Fragment key={sub?.route_code || sub?.title}>
        {sub?.has_separator && <hr style={{ margin: '0px', padding: '0px' }} />}
        <MenuItem
          to={sub?.to}
          fontIcon={sub?.icon}
          custIcon={sub?.cust_icon}
          title={<FormattedMessage id={sub?.title} />}
        />
      </Fragment>
    ) : null
  );

export function MenuInner() {
  const {
    userInfo: { features },
  } = useAuth();

  return (
    <>
      {menus.map((menu) =>
        features?.findIndex((f) => f?.code === menu.route_code) >= 0 ? (
          <MenuInnerWithSub
            key={menu.route_code || menu.title}
            fontIcon={menu.icon}
            title={<FormattedMessage id={menu.title} />}
            to={menu.to}
            menuPlacement='bottom-start'
            menuTrigger='click'
          >
            {renderSubMenu(menu.children, features)}
          </MenuInnerWithSub>
        ) : null
      )}

      <MenuInnerWithSub
        fontIcon='bi-graph-up-arrow'
        title={<FormattedMessage id='MENU.REPORTS' />}
        to={REPORTS}
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem
          to={PURCHASE_ORDER}
          fontIcon='bi-graph-up-arrow'
          title={<FormattedMessage id='MENU.DAILY_SUMMARY_REPORT' />}
        />
        <MenuItem
          to={RPT_SALES}
          fontIcon='bi-graph-up-arrow'
          title={<FormattedMessage id='MENU.SALES_REPORT' />}
        />
        <MenuItem
          to={RPT_RETURN}
          fontIcon='bi-graph-up-arrow'
          title={<FormattedMessage id='MENU.RETURN_REPORT' />}
        />
        <MenuItem
          to={RPT_REFUND}
          fontIcon='bi-graph-up-arrow'
          title={<FormattedMessage id='MENU.REFUND_REPORT' />}
        />
        <MenuItem
          to={RPT_PURCHASE_REQUISITION}
          fontIcon='bi-graph-up-arrow'
          title={<FormattedMessage id='MENU.PURCHASE_REQAUISITION_REPORT' />}
        />
        <MenuItem
          to={RPT_PURCHASE_ORDER}
          fontIcon='bi-graph-up-arrow'
          title={<FormattedMessage id='MENU.PURCHASE_ORDER_REPORT' />}
        />
        <MenuItem
          to={RPT_PURCHASES}
          fontIcon='bi-graph-up-arrow'
          title={<FormattedMessage id='MENU.PURCHASE_REPORT' />}
        />
        <MenuItem
          to={RPT_STOCK}
          fontIcon='bi-graph-up-arrow'
          title={<FormattedMessage id='MENU.STOCK_REPORT' />}
        />
        <MenuItem
          to={RPT_EXPENSES}
          fontIcon='bi-graph-up-arrow'
          title={<FormattedMessage id='MENU.EXPANSES_REPORT' />}
        />
      </MenuInnerWithSub>
    </>
  );
}
