import { COMPANY_ID } from '@constants/auth.constant';
import { COMPANY_LIST } from '@constants/internal-route.constant';
import { useAuth } from '@context/Auth';
import { IAppRoutes, IObject } from '@interface/common.interface';
import ChooseCompany from '@modules/auth/ChooseCompany';
import { setWindowTitle } from '@services/helper/common.helper';
import { LocalStorageService } from '@services/utils/localStorage.service';
import { TopProgressCom } from '@services/utils/topProgress.service';
import AuthLayout from 'layouts/AuthLayout';
import { MasterLayout } from 'layouts/MasterLayout';
import { Error404 } from 'pages/errors/components/Error404';
import { Unauthorized401 } from 'pages/errors/components/Unauthorized401';
import { Suspense, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AppRouteList } from './routes/app.routes';

const routeList = (routes: IAppRoutes[], features: IObject[]) => {
  return routes.map((route: IAppRoutes, index: number) => {
    const isUnauthorized = route?.routeCode && !features?.some((f) => f.code === route.routeCode);

    if (route.childrens && route.childrens.length) {
      return (
        <Route
          path={route.link}
          element={
            route.element && !isUnauthorized ? (
              <Suspense fallback={<TopProgressCom />}>
                <route.element />
              </Suspense>
            ) : isUnauthorized ? (
              <Unauthorized401 />
            ) : (
              <>
                <Outlet />
              </>
            )
          }
          key={index}
        >
          {routeList(route.childrens, features)}
        </Route>
      );
    }

    return (
      <Route
        path={route.link}
        element={
          route.redirect ? (
            <Navigate to={route.redirect} replace />
          ) : isUnauthorized ? (
            <Unauthorized401 />
          ) : (
            <Suspense fallback={<TopProgressCom />}>
              <route.element />
            </Suspense>
          )
        }
        key={index}
      />
    );
  });
};

const PrivateRoutes = () => {
  const {
    userInfo: { features },
  } = useAuth();
  setWindowTitle();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const cid: string = LocalStorageService.get(COMPANY_ID);
    if (location.pathname !== COMPANY_LIST && !cid) navigate(COMPANY_LIST);
  }, [location, navigate]);

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path={COMPANY_LIST} element={<ChooseCompany />} />
      </Route>
      <Route element={<MasterLayout />}>
        {routeList(AppRouteList, features)}
        <Route path='*' element={<Error404 />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
